
<template>
<div class="results_design_review py-5 bg-pastel-warning">
    <div class="container">
        <div class="row align-items-center">
        <div class="col-sm-6">
            <div class="results_design_review_img">
            <img class="img-fluid" src="/images/artwork-2.png" alt="" />
            </div>
        </div>
        <div class="col-sm-6">
            <div class="results_design_review_txt">
            <h3 class="text-dark mb-3">
                <span class="text-primary">Next Step:</span> Customize an
                income plan starting with these results.
            </h3>
            <p class="text-dark mb-4 font-size-lg">
                Click below to add these risks into a retirement plan. You can
                then add and remove risks that you want to plan for, add goals
                to your plan, and calculate the amount of income you may need
                to cover these costs.
            </p>
            <a
                href="#"
                class="btn btn-primary btn-lg font-size-sm shadow-none"
                >NEXT <i class="fas fa-arrow-right pl-1"></i
            ></a>
            </div>
        </div>
        </div>
    </div>
</div>
</template>


<script>
export default {
  props: {
    
  },
  methods: {

  }
};
</script>
