
<template>
<div class="results_design_review py-5 bg-pastel-warning  mt-5">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-6">
						<div class="results_design_review_img">
							<img class="img-fluid" src="/images/artwork-2.png" alt="">
						</div>
					</div>
					<div class="col-md-6">
						<div class="results_design_review_txt">
							<h3 class="text-dark mb-3"><span class="text-primary">Next Step:</span> Customize an income plan starting with these results.</h3>
							<p class="text-dark mb-4 font-size-lg">
								Click below to add these risks into a retirement plan. You can then add and remove risks that you want to plan for, add goals to your plan, and calculate the amount of income you may need to cover these costs.
							</p>
							<a href="#" class="btn btn-primary btn-lg btn-block font-size-sm shadow-none drift-open-chat" @click.prevent="openChat()">NEXT <i class="fas fa-arrow-right pl-1"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
  <!-- <div
    class="results_design_review py-4 mt-5"
    style="background-image: url('images/result.jpg')"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="results_design_review_img">
            <img class="img-fluid" src="/images/your-results.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="results_design_review_txt">
            <h3 class="text-dark mb-3">
              Review your results with a Heyday Consultant and get your FREE
              gift box!
            </h3>
            <p class="text-dark mb-4 font-size-lg">
              Do you have questions about these results? Wondering what the next
              steps in planning for these risks might be? Speak with a Live
              Heyday Consultant NOW and get a
              <a href="#">FREE "Ready to Retire" Gift Box</a> after your call!
            </p>
            <a href="https://www.heydayretirement.com/contact-us" target="_blank" class="btn btn-primary btn-lg font-size-sm shadow-none"
              >FREE CONSULTATION! <i class="fas fa-arrow-right pl-1"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>


<script>
export default {
  props: {},
  methods: {
    openChat() {
      Fire.$emit("openChat");  
    }
  },
};
</script>
