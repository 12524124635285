<template>
<div class="results_design_data_graph_box shadow giraffe">
    <div class="graph_icon">
      <i class="fal fa-chart-line-down text-primary"></i>
    </div>
    <!--<div class="graph_img">
        <vue-speedometer 
        :fluidWidth="true"
        :minValue="10"
        :maxValue="100"
        :value="score"
        needleColor="steelblue"
      /> 
    </div>-->
    <div class="box">
      <vue-justgage ref="g1" id="g1" class="gauge" style="padding: 30px;"></vue-justgage>
    </div>
    <div class="alert font-size-sm" role="alert" :class="{ 'alert-success' : data.color == 'green', 'alert-danger' : data.color == 'red'}">
        <i class="fal fa-exclamation-circle mr-1"></i> {{ data.caption }}.
    </div>
    <h4 class="text-dark">About Your Results</h4>
    <p class="text-dark">
      {{ data.description }}
    </p>
</div>
</template>
<script>
// import VueSpeedometer from "vue-speedometer"
// import vueJustgage from 'vue-justgage';
export default {
  components: { 
    // VueSpeedometer, 
    // vueJustgage 
  },
  data() {
    return {
      dflt: {
        min: 0,
        max: 100,
        // symbol: '%',
        pointer: true,
        gaugeWidthScale: 0.4,
        counter: true,
        levelColors: [
          "#F42A2A",
          "#F42A2A",
          "#239B0A",
          "#239B0A",
        ],
        noGradient: true,
        relativeGaugeSize: true,
        hideMinMax: true
      }
    }
  },
  props: {
    score: Number,
    data: Object,
  },
  mounted() {


    var g1 = this.$refs.g1.draw({
      id: 'g1',
      value: this.score,
      label: this.data.title,
      labelFontColor:this.data.color,
      defaults: this.dflt
    });

  },
  methods: {
    refresh() {
      this.$refs.g1.refresh(this.getRandomInt(0, 100));
    }
  }
};
</script>
<style scoped>
/* .results_design_data_graph_box .alert {
    margin: 20px auto 30px auto;
} 
.results_design_data_graph_box .box {
  position:relative;
  padding-top:52%;
}
.results_design_data_graph_box .box .gauge {
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:auto;
    margin-top: -20%;
}
*/
</style>