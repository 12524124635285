<template>
  <div class="results_design_data_graph_box shadow">
    <div class="graph_icon">
      <i class="fal fa-chart-bar text-primary"></i>
    </div>
    <div class="graph_img">
      <chart :graphId="graphId" :expensesData="expensesData" :incomeData="incomeData" />
    </div>
    <h4 class="text-dark">Retirement Outlook</h4>
    <p class="text-dark">
      This overview includes your fixed income and expenses, along with the
      forecasted cost of the risks you selected.
    </p>
  </div>
</template>
<script>
import Chart from "./Chart";
export default {
  components: {
    Chart
  },
  props: {
    graphId: {
      type: String,
      default: "main-chart",
    },
    expensesData: {
      type: Array,
      default: [],
    },
    incomeData: {
      type: Array,
      default: [],
    },
  }
};
</script>