
<template>
<div class="results_design_sidebar">
    <div class="results_design_sidebar_box">
    <h4 class="text-dark">Step by Step</h4>
    <ul>
        <li>
        <a
            href="#overview"
            :class="{'active' : activeLink == 'overview'}"
            v-scroll-to="{ 
                el: '#overview',
                onStart: onStart,
                onCancel: onCancel
            }"
        >
            <h6 class="text-dark">Overview</h6>
            <p class="text-muted">
            Savings protection index and retirement outlook.
            </p>
        </a>
        </li>
        <li :key="index" v-for="(item, index) in section">
            <a :href="'#'+item.id+'_retirement'"  
            :class="{'active' : activeLink == item.id+'_retirement'}"
            v-scroll-to="{ 
                el: '#'+item.id+'_retirement',
                onStart: onStart,
                onCancel: onCancel
            }">
                <h6 class="text-dark">{{ item.title }}</h6>
                <p class="text-muted">Ages {{ item.startAge }} - {{ item.endingAge }}</p>
            </a>
        </li>
    </ul>
    </div>
</div>

</template>


<script>
export default {
    data() {
        return {
           link :  null
        }
    },
    props: {
        activeLink: String,
        section: Array
    },
    mounted() {
        // this.link = this.activeLink;
    },
    created() {
        // window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        // window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        onStart(element) {
            this.activeLink = element.id;
            // this.link = element.id;
        },
        onCancel(element) {
            console.log(element);
        },
        handleScroll(event) {
            // let that = this;
            // var scrollPos = $(document).scrollTop();
            // $('.results_design_sidebar_box a').each(function() {
            //     var currLink = $(this);
            //     var refElement = $(currLink.attr("href"));
            //     if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            //         // $('.results_design_sidebar_box ul li a').removeClass("active");
            //         console.log(currLink.attr("href") + ' == ' + that.link);
            //         // currLink.addClass("active");
            //         if(that.link != currLink.attr("href")) {
            //             that.link = currLink.attr("href");
            //         }
            //     } else {
            //         //   console.log(currLink.attr("href"));
            //         //   currLink.removeClass("active");
            //     }
            // });
        },
    }
};
</script>
