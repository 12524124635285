<template>
  <div>
    <canvas :id="graphId"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
import numAbbr from "number-abbreviate";
import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  props: {
    graphId: {
      type: String,
      default: "main-chart",
    },
    expensesData: {
      type: Array,
      default: [],
    },
    incomeData: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    // console.log("canvas expensesData => "+this.graphId);
    // console.log(this.expensesData);

    // console.log("canvas incomeData => "+this.graphId);
    // console.log(this.incomeData);

    this.createChart(this.graphId, this.incomeData, this.expensesData);
  },
  methods: {
    createChart(chartId, incomeData, expensesData) {
      // const ctx = document.getElementById(chartId);
      const ctx = $("#" + chartId)[0].getContext("2d");
      // console.log(ctx);

      const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
      gradientFill.addColorStop(0, "rgba(0, 118, 255, 0.1)");
      gradientFill.addColorStop(1, "rgba(0, 118, 255, 0)");

      const gradientFill2 = ctx.createLinearGradient(0, 0, 0, 400);
      gradientFill2.addColorStop(0, "rgba(255, 31, 31, .1)");
      gradientFill2.addColorStop(1, "rgba(255, 31, 31, 0)");

      const baseLineOptions = {
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointBorderWidth: 0,
        pointRadius: 0,
        borderWidth: 1,
        lineTension: 0,
      };

      Chart.defaults.global.defaultFontFamily = "'Muli', sans-serif";

      const allData = [...incomeData, ...expensesData];
      // Round to nearest 10000
      const maxY =
        Math.ceil(Math.max(...allData.map((data) => data.y)) / 10000) * 10000;
      const minY =
        Math.floor(Math.min(...allData.map((data) => data.y)) / 10000) * 10000;

      let config = {
        plugins: [ChartDataLabels],
        type: "line",
        data: {
          datasets: [
            {
              label: "Income",
              borderColor: "rgb(0, 106, 230)",
              data: incomeData,
              backgroundColor: gradientFill,
              ...baseLineOptions,
            },
            {
              label: "Expenses",
              borderColor: "#FF1F1F",
              backgroundColor: gradientFill2,
              data: expensesData,
              datalabels: {
                color: "#FF1F1F",
                borderColor: "#FF1F1F",
              },
              ...baseLineOptions,
            },
          ],
        },
        options: {
          responsive: true,
          animation: {
            easing: "easeInOutBack",
          },
          legend: {
            position: "top",
            labels: {
              boxWidth: 4,
              usePointStyle: true,
              generateLabels: function (chart) {
                return chart.data.datasets.map((dataset) => {
                  return {
                    datasetIndex: 0,
                    fillStyle: dataset.borderColor,
                    strokeStyle: dataset.borderColor,
                    lineWidth: dataset.borderWidth,
                    text: dataset.label,
                    pointStyle: "circle",
                  };
                });
              },
            },
          },
          scales: {
            xAxes: [
              {
                type: "linear",
                position: "bottom",
                ticks: {
                  precision: 0,
                  fontColor: "#000000",
                  min: Math.min(...incomeData.map((data) => data.x)),
                },
                gridLines: {
                  drawTicks: true,
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "#000000",
                  maxTicksLimit: 8,
                  padding: 10,
                  callback: (value) => `$${numAbbr(value, 1)}`,
                  max: maxY,
                  min: minY,
                  stepSize: (maxY - minY) / 8,
                },
                gridLines: {
                  lineWidth: 0,
                  drawTicks: false,
                  drawBorder: true,
                },
              },
            ],
          },
          plugins: {
            datalabels: {
              borderWidth: 1,
              borderRadius: 9999,
              padding: {
                top: 4,
                bottom: 3,
                left: 7,
                right: 7,
              },
              align: "end",
              offset: 10,
              formatter: (value) => value.label || null,
              display: (context) => {
                return context.dataset.data[context.dataIndex];
              },
            },
          },
        },
      };

      console.log(config);
      console.log(JSON.stringify(config));

      new Chart(ctx, config);
    },
  },
};
</script>