var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"results_design_sidebar"},[_c('div',{staticClass:"results_design_sidebar_box"},[_c('h4',{staticClass:"text-dark"},[_vm._v("Step by Step")]),_c('ul',[_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ 
                el: '#overview',
                onStart: _vm.onStart,
                onCancel: _vm.onCancel
            }),expression:"{ \n                el: '#overview',\n                onStart: onStart,\n                onCancel: onCancel\n            }"}],class:{'active' : _vm.activeLink == 'overview'},attrs:{"href":"#overview"}},[_c('h6',{staticClass:"text-dark"},[_vm._v("Overview")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Savings protection index and retirement outlook. ")])])]),_vm._l((_vm.section),function(item,index){return _c('li',{key:index},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ 
                el: '#'+item.id+'_retirement',
                onStart: _vm.onStart,
                onCancel: _vm.onCancel
            }),expression:"{ \n                el: '#'+item.id+'_retirement',\n                onStart: onStart,\n                onCancel: onCancel\n            }"}],class:{'active' : _vm.activeLink == item.id+'_retirement'},attrs:{"href":'#'+item.id+'_retirement'}},[_c('h6',{staticClass:"text-dark"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"text-muted"},[_vm._v("Ages "+_vm._s(item.startAge)+" - "+_vm._s(item.endingAge))])])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }