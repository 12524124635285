import { clamp, range } from 'lodash'
import { APPLIES_TO_ME_VALUE } from './constants'
import { RISK_ASSESSMENT_QUESTION_IDs } from '@/data/risk-assessment-categories'

export const getIndexData = score => {
  const SCORE_TEXT = [
    {
      color: 'red',
      title: 'High Risk',
      caption: 'Your savings may be at risk',
      description:
        'Your Savings Protection Index is very low. This happens when a substantial amount of retirement risks are anticipated and are not forecasted to be met with income sources such as social security, pensions, and annuities. This could result in a very high reliance on your savings.'
    },
    {
      color: 'red',
      title: 'Very Low',
      caption: 'Your savings may be at risk',
      description:
        'Your Savings Protection Index is very low. More than half of your potential retirement risks are not forecasted to be met with income sources such as social security, pensions, and annuities. This could erode your savings.'
    },
    {
      color: 'red',
      title: 'Needs Improvement',
      caption: 'Your Savings Protection Index needs improvement',
      description:
        'Your Savings Protection Index needs improvement. A fair amount of retirement risks are not forecasted to be met with income sources such as social security, pensions, and annuities. You may have to rely on savings more than you anticipated.'
    },
    {
      color: 'green',
      title: 'Good',
      caption: 'Your Savings Protection Index is good',
      description:
        'Your Savings Protection Index is good. A substantial portion of your potential retirement risks might be met with income sources such as social security, pensions, and annuities. Your savings could possibly be planned for additional travel, kept for emergencies, or passed to heirs.'
    }
  ]

  if (score === 0) return SCORE_TEXT[0]
  if (score === 100) return SCORE_TEXT[3]
  return SCORE_TEXT[Math.floor(score / 25)]
}

export const calculateRetirementIndex = (totalIncome, totalExpenses) => {
  return Math.floor(clamp((totalIncome / totalExpenses) * 100, 0, 100))
}

const filteredRisksWithExpenses = riskAssessmentResponses => {
  const risksThatApply = riskAssessmentResponses.filter(
    response => response.answer === APPLIES_TO_ME_VALUE
  )
  const risksThatApplyIds = risksThatApply.map(response => response.id)

  return risksThatApply
    .filter(response => response.expenses)
    .filter(
      response =>
        !response.expenseOverrides ||
        // remove risk if its override is selected
        !response.expenseOverrides.some(overrideRiskId =>
          risksThatApplyIds.includes(overrideRiskId)
        )
    )
}

export const calculateTopRisks = (riskAssessmentResponses, currentAge) => {
  return filteredRisksWithExpenses(riskAssessmentResponses)
    .map(response => {
      return {
        id: response.id,
        expensesStartingAge: Math.min(...response.expenses.map(expense => expense.age)),
        totalExpense: response.expenses.reduce((total, expense) => {
          if (expense.age >= currentAge) {
            total += expense.yearlyExpenseChange
          }

          return total
        }, 0)
      }
    })
    .sort((a, b) => b.totalExpense - a.totalExpense)
    .slice(0, 3)
}

export const calculateExpensesChartData = (currentAge, sliderExpenses, riskAssessmentResponses) => {
  const baseTotalYearlySliderExpenses = sliderExpenses.reduce((total, expense) => {
    const expenseYearly = expense.value * (expense.timeFrame === 'mo' ? 12 : 1)
    return total + expenseYearly
  }, 0)

  const riskAssessmentExpenses = filteredRisksWithExpenses(riskAssessmentResponses)
    .map(response => response.expenses)
    .flat(1)
    .reduce((acc, expense) => {
      acc[expense.age] = (acc[expense.age] || 0) + expense.yearlyExpenseChange
      return acc
    }, {})

  const inflationRiskApplies = Boolean(
    riskAssessmentResponses.find(
      response =>
        response.id === RISK_ASSESSMENT_QUESTION_IDs.inflating_expenses &&
        response.answer === APPLIES_TO_ME_VALUE
    )
  )
  const inflationRate = 0.017 * (inflationRiskApplies ? 1.5 : 1)

  const expensesChartData = range(currentAge, 101).map((age, idx) => {
    return {
      x: age,
      y:
        baseTotalYearlySliderExpenses * Math.pow(1 + inflationRate, idx) +
        (riskAssessmentExpenses[age] || 0)
    }
  })

  return expensesChartData
}

export const addTopRiskLabelsToExpenseChartData = (expensesChartData, topRisks) =>
  expensesChartData.map(data => {
    const riskIndex = topRisks.findIndex(risk => risk.expensesStartingAge === data.x)

    return {
      ...data,
      ...(riskIndex > -1 && { label: riskIndex + 1 })
    }
  })

export const calculateIncomeChartData = (
  currentAge,
  retired,
  retirementAge,
  preRetirementIncome,
  retirementIncome
) => {
  // fixed constant value
  const totalYearlyPreRetirementIncome = preRetirementIncome.reduce((total, income) => {
    const incomeYearly = income.value * (income.timeFrame === 'mo' ? 12 : 1)
    return total + incomeYearly
  }, 0)

  return range(currentAge, 101).map((age, idx) => {
    return {
      x: age,
      y:
        yearlyRetirementIncome(age, retirementIncome, idx) +
        (!retired && age < retirementAge ? totalYearlyPreRetirementIncome : 0)
    }
  })
}

// variable value that changes based on age (different incomes start different ages)
// Also possible that some income starts before retirement_age value
const yearlyRetirementIncome = (age, retirementIncome, yearIndex) => {
  return retirementIncome.reduce((total, income) => {
    if (age >= income.startingAge) {
      total +=
        income.value *
        (income.timeFrame === 'mo' ? 12 : 1) *
        (income.inflationRate ? Math.pow(1 + income.inflationRate, yearIndex) : 1)
    }

    return total
  }, 0)
}
