<template>
<div class="page_content_wrap">
	<div class="results_design_wrap pt-5" v-if="!isLoading">
      <div class="results_design_wrap_content">
        <div class="results_design_content pb-7">
        
          <side-menu :activeLink="activeLink" :section="breakdownSectionsData" />
          
          <div class="results_design_data">

            <div id="overview">

              <div class="results_design_data_head mb-5">
                <h2 class="text-dark">
                  <img src="/images/alart.svg" alt="">
                  Here’s how your <span class="text-primary">expenses & risks</span> could impact your retirement income and savings.
                </h2>
              </div>

              <div class="results_design_data_graph pb-5">
                <div class="row">
                  <div class="col-lg-12 col-xl-6">
                    <meters v-if="SCORE_SECTION_DATA" :score="SCORE" :data="SCORE_SECTION_DATA" />
                  </div>
                  <div class="col-lg-12 col-xl-6">
                    <chart-box 
                      :graphId="'main-chart'"
                      :expensesData="expensesData"
                      :incomeData="incomeChartData"
                    />
                  </div>
                </div>
              </div>

              <div class="results_design_data_cont">
                <div class="results_design_data_cont_head mb-5">
                  <i class="fal fa-exclamation-triangle text-primary"></i>
                  <h3 class="text-dark mb-3">Your Retirement Income and Risk Projections</h3>
                  <p class="text-dark mb-5">The top 3 risks with the potential to negatively impact your retirement are:</p>
                  <img src="/images/result/icons-Start-age-50-to-60.svg" alt="icons-Start-age-50-to-60" />
                </div>

                <div class="results_design_data_cont_accordion">

                  <div class="accordion" id="accordion_one">
                    <div class="accordion_box" :class="{ 'accordion-active' : (checkIncludes('Long-term Care') || checkIncludes('Long-Term Care')) }">
                        <div class="accordion_header">
                            <div class="checkbox_btn btn-group-toggle" data-toggle="buttons">
                          <label class="btn btn-outline-secondary">
                            <input type="checkbox" value="Long-term Care" @change="changeStar($event)" />
                            <i class="fas fa-star"></i>
                          </label>
                          </div>
                            <h5 class="" data-toggle="collapse" data-target="#accordion_one_1" aria-expanded="false">01. Long-Term Care</h5>
                        </div>
                        <div id="accordion_one_1" class="collapse show" data-parent="#accordion_one">
                          <div class="accordion_body">Long-Term Care prices have, historically, steadily increased. We should assume this trend will continue. Current national averages are approximately $7,000 per month.</div>
                        </div>
                    </div>
                    <div class="accordion_box" :class="{ 'accordion-active' : checkIncludes('Home Maintenance and Repairs')}">
                        <div class="accordion_header">
                          <div class="checkbox_btn btn-group-toggle" data-toggle="buttons">
                          <label class="btn btn-outline-secondary">
                            <input type="checkbox" value="Home Maintenance and Repairs" @change="changeStar($event)" />
                            <i class="fas fa-star"></i>
                          </label>
                          </div>
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_2" aria-expanded="false">02. Home Maintenance and Repairs</h5>
                        </div>
                        <div id="accordion_one_2" class="collapse" data-parent="#accordion_one">
                          <div class="accordion_body">Unfortunately, our homes age and need constant maintenance. New roofs, air-conditioners, and renovations are just a few of the larger lump sum costs that should be considered during retirement.</div>
                        </div>
                    </div>
                    <div class="accordion_box" :class="{ 'accordion-active' : checkIncludes('Healthcare Costs After Age 65')}">
                        <div class="accordion_header">
                          <div class="checkbox_btn btn-group-toggle" data-toggle="buttons">
                          <label class="btn btn-outline-secondary">
                            <input type="checkbox" value="Healthcare Costs After Age 65" @change="changeStar($event)" />
                            <i class="fas fa-star"></i>
                          </label>
                          </div>
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_3" aria-expanded="false">03. Healthcare Costs After Age 65</h5>
                        </div>
                        <div id="accordion_one_3" class="collapse" data-parent="#accordion_one">
                            <div class="accordion_body">While Medicare premiums should be manageable, medical emergencies can carry out of pocket maximums as high as $7,000-$10,000.</div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <template v-for="(section, secIndex) in breakdownSectionsData">
                
                <div :id="section.id+'_retirement'" :key="section.id"  class="results_design_data_cont pt-6 mt-5">
                  
                  <div class="results_design_data_cont_head mb-5">
                    <i class="fal fa-exclamation-triangle text-primary"></i>
                    <h3 class="text-dark mb-3">{{section.title}} <span class="h6 text-primary">Age {{section.startAge}} - {{ section.endingAge }}</span></h3>
                    

                    
                    <img :src="section.image" :alt="section.title" />
                  </div>
                  <div class="p-4 shadow mb-4" v-if="incomeChartData && expensesChartData">
                      <div class="graph_icon float-right">
                        <i class="fal fa-chart-bar text-primary"></i>
                      </div>
                      <div class="graph_img">
                        <chart 
                            :graphId="'main-chart-'+secIndex"
                            :incomeData="incomeChartData.filter(
                              data => section.endingAge >= data.x && data.x >= section.startAge
                            )"
                            :expensesData="expensesChartData.filter(
                              data => section.endingAge >= data.x && data.x >= section.startAge
                            )"
                          />
                      </div>
                      <p class="text-dark" :key="index" v-for="(content, index) in section.description">
                          {{ content }}
                      </p>
                    </div>

                    <!-- <p class="text-dark mb-3" :key="index" v-for="(content, index) in section.description">
                      {{ content }}
                    </p> -->

                  <p class="text-dark">Noteworthy contributing factors:</p>
                  <div class="results_design_data_cont_accordion">
                    <div class="accordion" :id="'accordion_'+secIndex" >
                      <div class="accordion_box" :key="index" v-for="(accordian, index) in section.topContributingFactors" :class="{ 'accordion-active' : checkIncludes(accordian.title) }">
                          <div class="accordion_header">
                            <div class="checkbox_btn btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-outline-secondary">
                              <input type="checkbox" :value="accordian.title" @change="changeStar($event)">
                              <i class="fas fa-star"></i>
                            </label>
                            </div>
                              <h5 class="collapsed" data-toggle="collapse" :data-target="'#accordion_'+secIndex+'_'+ index" aria-expanded="false">0{{index + 1}}. {{ accordian.title }}</h5>
                          </div>
                          <div :id="'accordion_'+secIndex+'_'+ index" class="collapse" :data-parent="'#accordion_'+secIndex">
                            <div class="accordion_body">
                              {{ accordian.content }}
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </template>

            <!-- <hr /> -->

          </div>
        </div>
      </div>

      <!-- <div class="results-box-link text-center">
        <p class="font-size-sm">Click here to learn more about few major things to plan for that our expert recommend.</p>
        <button class="btn btn-primary btn-lg font-size-sm shadow-none" :disabled="selectedStars.length < 3" @click="submitForm">PLANNING RECOMMENDATIONS <i class="fas fa-arrow-right pl-1"></i></button>
      </div> -->

      <consultation />

      <!-- <customize /> -->

      <more-info />
      
      <div class="footer_wrap text-center py-2 bg-purple text-white">
        <div class="container">
          <img src="/images/assistants.png" alt="" />
          Talk with a Heyday Assistant
          <a href="javascript:void(0);" @click="openChat" class="btn btn-primary btn-lg font-size-sm">CHAT NOW</a>
        </div>
      </div>
  </div>
  <div class="loader" v-if="isLoading"></div>
</div>
</template>


<script>
import Meters from './Meters';
import ChartBox from './ChartBox';
import Chart from './Chart';

import SideMenu from './SideMenu';
import Customize from './Customize';
import Consultation from './Consultation';
import MoreInfo from './MoreInfo';

import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";
import { sumBy } from "lodash";
import {
  calculateExpensesChartData,
  calculateIncomeChartData,
  calculateTopRisks,
  calculateRetirementIndex,
  getIndexData,
  addTopRiskLabelsToExpenseChartData,
} from "@/utils/results";
import {
  HUBSPOT_EXPENSES_LIST_KEY,
  HUBSPOT_DISPOSABLE_INCOME_LIST_KEY,
  HUBSPOT_FIXED_INCOME_LIST_KEY,
  APPLIES_TO_ME_VALUE,
} from "@/utils/constants";
import CARD_QUESTIONS from "@/data/risk-assessment-categories";
import { includes } from 'lodash';

const cardQuestionsData = Object.keys(CARD_QUESTIONS)
  .map((category) => CARD_QUESTIONS[category].questions)
  .flat(1);

const getAccordionData = (questionIds) =>
  questionIds
    .map((id) => cardQuestionsData.find((question) => id === question.id))
    .map((obj) => ({ title: obj.title, content: obj.accordionText }));

// const openDriftChat = () => {
//   setTimeout(() => window.drift.api.openChat(), 300);
//   window.drift.api.hideChat();
// };

import Vue from 'vue'
// import VueDrift from 'vue-drift'

// Vue.use(VueDrift, {
//   appId: process.env.VUE_APP_DRIFT_ID,
// })

export default {
  name: "Screen13",
  components: {
    SideMenu,
    Customize,
    Consultation,
    MoreInfo,
    Meters,
    ChartBox,
    Chart
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      errors: [],
      breakdownSectionsData: [],
      activeLink: 'overview',
      SCORE_SECTION_DATA: null,
      SCORE: 0,
      expensesData: [],
      incomeChartData: [],
      expensesChartData: [],
      additionalFectores: [],
      selectedStars: []
    }
  },
  computed: {
    ...mapGetters({
      getUserId: "getUserId",
      getUser: "getUser",
    })
  },
  mounted() {
  
  Fire.$on('openChat', () => {
    // this.$drift.show();
    // openDriftChat();
    // $('body').find('.drift-widget-controller').trigger('click');
  });

	this.isLoading = true;
        
  getContact([
		HUBSPOT_DISPOSABLE_INCOME_LIST_KEY,
		HUBSPOT_EXPENSES_LIST_KEY,
		HUBSPOT_FIXED_INCOME_LIST_KEY,
		'retirement_age',
		'are_you_retired',
		'age',
		...cardQuestionsData.map(question => question.id)
	]).then((response) => {

		let prop = response;//.data.properties;
		// console.log("response.data.properties");
		// console.log(prop);
      if(this.getUser) {
         // this.$drift.identify(this.getUserId, {
         //    name: this.getUser.name,
         //    email: this.getUser.email
         //  })
      }
    
		 const riskAssessmentResponses = cardQuestionsData.map((question) => {
			return {
				...question,
				answer: prop[question.id],
			};
			});

			this.expensesChartData = calculateExpensesChartData(
			prop.age,
			prop[HUBSPOT_EXPENSES_LIST_KEY]
				? JSON.parse(prop[HUBSPOT_EXPENSES_LIST_KEY])
				: [],
			riskAssessmentResponses
			);
      
  
			this.incomeChartData = calculateIncomeChartData(
			prop.age,
			prop.are_you_retired === "Retired",
			prop.retirement_age,
			prop[HUBSPOT_DISPOSABLE_INCOME_LIST_KEY]
				? JSON.parse(prop[HUBSPOT_DISPOSABLE_INCOME_LIST_KEY])
				: [],
			prop[HUBSPOT_FIXED_INCOME_LIST_KEY]
				? JSON.parse(prop[HUBSPOT_FIXED_INCOME_LIST_KEY])
				: []
			);

     

			const topRisks = calculateTopRisks(
			riskAssessmentResponses,
			prop.age
			);

      this.expensesData = addTopRiskLabelsToExpenseChartData(this.expensesChartData, topRisks);

      console.log('this.incomeChartData')
      console.log(JSON.stringify(this.incomeChartData));

      console.log('this.expensesData')
      console.log(JSON.stringify(this.expensesData));

			const SCORE = calculateRetirementIndex(sumBy(this.incomeChartData, "y"), sumBy(this.expensesChartData, "y"));
      this.SCORE = SCORE;

      this.SCORE_SECTION_DATA = getIndexData(SCORE);
  
      console.log('SCORE');
      console.log(this.SCORE);

      console.log('this.SCORE_SECTION_DATA');
      console.log(JSON.stringify(this.SCORE_SECTION_DATA));
			// MID & LATE dont have open items, but might help provide some content to them
			// Basically just showing the first 3 risks only applicable to that stage, regardless of impact
			const OPEN_ITEM_IDS = {
			early: [
				"retirement_income_shock",
				"cancelled_plans",
				"early_retirement_income_shortfall",
				"healthcare_costs_age_65_and_under",
			].filter((id) => prop[id] === APPLIES_TO_ME_VALUE),
			mid: [
				"emergency_fund_for_unknown_expenses",
				"aging_parents",
				"kids_and_grandkids",
				"home_maintenance_and_repairs",
			].filter((id) => prop[id] === APPLIES_TO_ME_VALUE),
			late: [
				"medical_expenses_after_age_65_for_you_or_your_spouse",
				"uncovered_prescription_costs",
				"dental_care",
				"aging_in_place",
				"long_term_care",
				"home_healthcare_skilled_or_unskilled",
			].filter((id) => prop[id] === APPLIES_TO_ME_VALUE),
			general: [
				"the_salary_cliff",
				"economic_uncertainty",
				"running_out_of_money",
				"loss_of_a_spouse",
				"heirs",
			].filter((id) => prop[id] === APPLIES_TO_ME_VALUE),
			};

			this.breakdownSectionsData = [
			{
				id: "early",
        title: "Risks in Early Retirement",
        image: '/images/result/age-60-70.svg',
				startAge: 65,
				endingAge: 70,
				description: [
				"Your early retirement years include a shift from a regular salary to retirement income sources. This is a critical time, when savings can potentially be hit the hardest, or protected.",
				],
				topContributingFactors: getAccordionData(
				OPEN_ITEM_IDS["early"].slice(0, 3)
        ),
			},
			{
				id: "mid",
        title: "Plan for Middle Retirement",
        image: '/images/result/age-70-80.svg',
				startAge: 70,
				endingAge: 80,
				description: [
				'This is "prime time" for enjoying retirement dreams, including travel and other activities. Risks in this phase may negatively impact a retiree\'s lifestyle and cause them to "underlive" their potential.',
				],
				topContributingFactors: getAccordionData(
				OPEN_ITEM_IDS["mid"].slice(0, 3)
        ),
			},
			{
				id: "late",
        title: "Possible Problems in Late Retirement",
        image: '/images/result/age-80.svg',
				startAge: 80,
				endingAge: 100,
				description: [
				'"Running out of money" is consistently a top issue for retirees. Unexpected expenses coupled with longevity can contribute to this major concern.',
				],
				topContributingFactors: getAccordionData(
				OPEN_ITEM_IDS["late"].slice(0, 3)
        ),
			},
			].filter((section) => prop.age <= section.endingAge);

      this.additionalFectores = getAccordionData(OPEN_ITEM_IDS['general']);

      console.log('this.breakdownSectionsData');
      console.log(this.breakdownSectionsData);

      this.isLoading = false;
	})
	.catch((error) => {
		console.log("error");
		console.log(error);
		this.isLoading = false;
	});

  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    submitForm() {
      this.$router.push({ name : 'risk.planningRecommendation'});
    },
    changeStar(e) {
      if(e.target.checked) {
          this.selectedStars.push(e.target.value);
      } else {
        let selected = this.selectedStars;
        this.selectedStars.forEach((item, index) => {
          if(item == e.target.value) {
            this.selectedStars.splice(index, 1);
          }
        })
      }
    },
    checkIncludes(value) {
      return includes(this.selectedStars, value);
    },
    openChat() {
      Fire.$emit("openChat");  
    }
  }
};
</script>
