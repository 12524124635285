
<template>
<div class="results_design_more_info py-5">
    <div class="container">
        <div class="accordion shadow" id="accordion_more">
        <div
            class="results_design_more_info_head collapsed"
            data-toggle="collapse"
            data-target="#accordion_more_1"
            aria-expanded="true"
        >
            <i class="fal fa-globe text-primary bg-pastel-primary"></i>
            <h4 class="text-dark mb-2">More Info</h4>
            <p class="text-dark">
            What is the Savings Protection Index? This score calculates the
            percentage of potential expenses that could be covered by your
            fixed income. A lower score means that potential expenses may
            need to be paid for by savings. A higher score means your income
            may cover more of the expenses, protecting your nest egg from
            erosion.
            </p>
        </div>

        <div
            id="accordion_more_1"
            class="collapse"
            data-parent="#accordion_more"
        >
            <div class="results_design_more_info_body">
            <ul>
                <li>https://www.ssa.gov/OACT/COLA/colaseries.html</li>
                <li>
                https://www.morningstar.com/content/dam/marketing/shared/research/foundational/677785-EstimatingTrueCostRetirement.pdf
                </li>
                <li>
                https://www.fool.com/retirement/2018/12/07/heres-how-much-it-costs-parents-to-have-adult-kids.aspx
                </li>
                <li>
                https://money.com/so-your-adult-child-moved-back-home-heres-how-to-set-limits-fast/
                </li>
                <li>
                https://www.kiplinger.com/article/retirement/T027-C000-S004-finding-health-insurance-before-medicare.html
                </li>
                <li>
                https://www.fidelity.com/viewpoints/retirement/transition-to-medicare
                </li>
                <li>
                https://money.usnews.com/money/retirement/medicare/articles/health-insurance-options-when-retiring-early
                </li>
                <li>
                https://www.forbes.com/sites/dianeomdahl/2019/01/24/medigap-plan-f-or-plan-g/#497996c53cc3
                </li>
                <li>
                https://www.aarpsupplementalhealth.com/content/dam/ole/MedSuppDocs/BenefitsTable/StatePlanVariations/FLOOC_0120_wc1.pdf
                </li>
                <li>
                https://www.aarpmedicaresupplement.com/content/dam/aarpmedicaresupplement/aarp-pdfs/ahead-pdf/WB26150ST%20(03-19)_AHD.pdf
                </li>
                <li>
                https://www.nerdwallet.com/blog/investing/what-will-you-spend-on-health-care-costs-in-retirement/
                </li>
                <li>
                https://www.investopedia.com/retirement/how-plan-medical-expenses-retirement/
                </li>
                <li>
                https://crr.bc.edu/wp-content/uploads/2017/10/wp_2017-13.pdf
                </li>
                <li>
                https://money.cnn.com/retirement/guide/insurance_health.moneymag/index17.htm#:~:text=Medicare%20Part%20D%20provides%20prescription,buy%20from%20a%20private%20insurer.&amp;text=Once%20you%20cover%20the%20deductible,total%20drug%20costs%20per%20year
                </li>
                <li>
                https://www.investopedia.com/retirement/how-plan-medical-expenses-retirement/#citation-17
                </li>
                <li>
                https://southlanddentalcare.com/full-mouth-dental-implants-cost-los-angeles/
                </li>
                <li>
                https://www.bankrate.com/finance/insurance/dental-insurance-1.aspx#:~:text=People%20with%20dental%20insurance%20commonly,for%20crowns%2C%20bridges%20and%20major
                </li>
                <li>
                https://www.angieslist.com/articles/how-much-does-roof-replacement-cost.htm#:~:text=How%20Much%20Do%20Roofing%20Materials,is%20between%20%246.37%20and%20%248.77
                </li>
                <li>
                https://www.homeadvisor.com/cost/roofing/install-a-roof/
                </li>
                <li>
                https://roofadvisor.com/how-often-should-you-replace-your-roof/#:~:text=Composition%20Shingles%3A%2012%2D20%20years,Rubber%20Roofs%3A%2030%2D50%20years
                </li>
                <li>
                https://www.pickhvac.com/central-air-conditioner/replacement-cost/
                </li>
                <li>
                https://www.cousinsair.com/blog/2017/may/how-long-do-air-conditioners-last-in-florida-/#:~:text=Many%20professionals%20say%20that%2C%20roughly,and%20subject%20to%20weather%20extremes
                </li>
                <li>
                https://www.homeadvisor.com/r/moving-in-aging-or-disabled-parent/
                </li>
                <li>
                https://www.kompareit.com/homeandgarden/aginganddisability-retrofit-to-wheelchair.html#:~:text=Complete%20bathroom%20retrofit%3A%20%2415%2C000%20to,permanent%20ramp%3A%20%241%2C500%20to%20%2415%2C000
                </li>
                <li>
                https://longtermcare.acl.gov/costs-how-to-pay/costs-of-care.html#:~:text=Below%20are%20some%20national%20average,room%20in%20a%20nursing%20home
                </li>
                <li>http://www.caregiverlist.com/SeniorCareCosts.aspx</li>
                <li>
                https://www.genworth.com/aging-and-you/finances/cost-of-care.html
                </li>
                <li>
                https://longtermcare.acl.gov/costs-how-to-pay/costs-of-care.html#:~:text=Below%20are%20some%20national%20average,room%20in%20a%20nursing%20home
                </li>
            </ul>
            </div>
        </div>
        </div>
    </div>
</div>
</template>


<script>
export default {
  props: {},
  methods: {},
};
</script>
